import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { ThemeContext } from 'components/Theme/Context';
import * as S from './styled';
import { useConfig } from 'utils/hooks';

type Props = {
  messageId?: string;
  onClick?: Function;
  to?: string;
  alt?: boolean;
  $color?: string;
  label?: string;
};

function LinkButton({
  messageId,
  label,
  onClick,
  to = '',
  alt = false,
  $color,
}: Props) {
  // const { theme } = useContext(ThemeContext);
  const { defaults: theme } = useConfig();

  console.log('THEEME ****', theme);
  return onClick ? (
    <S.LinkButtonS
      type="button"
      theme={theme}
      alty={alt ? 'y' : 'n'}
      onClick={onClick as any}
      $color={$color}
    >
      {label ? label : <FormattedMessage id={messageId} />}
    </S.LinkButtonS>
  ) : (
    <S.StyledLink theme={theme} alty={alt ? 'y' : 'n'} to={to}>
      {label ? label : <FormattedMessage id={messageId} />}
    </S.StyledLink>
  );
}

export default LinkButton;
