import React, { useEffect, useState } from 'react';
import * as S from './styled';
import { useHistory } from 'react-router-dom';
import { useLoggedIn, useConfig } from 'utils/hooks';
import { useOEMList, useOEMUserList } from 'features/page/hooks';

const QuickLinks = () => {
  const [list, setList] = useState<any[]>([]);
  const history = useHistory();
  const { list: listOems } = useOEMList(); // Get OEM list data

  useEffect(() => {
    const f = async () => {
      const lst = await listOems();
      setList(lst?.data);
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (route) => {
    // history.push(route);
    const host = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
    window.location.href = `${host}/${route}`;
  };

  const isDev =
    window.location.host.includes('dev') ||
    window.location.host.includes('localhost');

  console.log({ list });
  return (
    <S.QuickLinksWrapper>
      <S.Heading>
        Please click on the specific manufacturer that you wish to access
      </S.Heading>
      <S.TilesWrapper>
        {list
          ?.slice()
          ?.sort(({ id: a }, { id: b }) => a - b)
          ?.map((item, index) => {
            if (item.path == 'r2r' || item.path == 'ineos') return null;
            if (item.hidemenu && !(isDev && item.devEnabled)) return null;
            return (
              <S.Tile
                key={index}
                onClick={() => {
                  // try {
                  //   (window as any).updateChatbot(item.path);
                  // } catch (e) {}
                  handleClick(`${item.path}`);
                }}
              >
                <S.TileImage src={item.homepagelisticon} alt={item.Name} />
                <S.TileLabel>{item.Name}</S.TileLabel>
              </S.Tile>
            );
          })}
      </S.TilesWrapper>
    </S.QuickLinksWrapper>
  );
};
export default QuickLinks;
